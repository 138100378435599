import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { useBottomSheet } from '@/context/bottom-sheet-context'
import { useNavigation } from '@/app/hooks'
import { useMockableViewModel } from '@/hooks'
import { UtilityProgram } from '@/types/utilityProgram'
import { programRequirementsSheetConfig } from '@/app/components/ProgramRequirementsSheet/ProgramRequirementsSheet'

function useViewModel() {
  const { id: utilityProgramId } = useParams<{ id: string }>()
  const { data: utilityPrograms } =
    searchableUtilityProgramsCollection.useFetch({
      path: `utility_programs/${utilityProgramId}`,
    })
  const utilityProgram = utilityPrograms?.[0] as UtilityProgram
  const programContent = useMemo(() => {
    if (!utilityProgram) {
      return null
    }
    return {
      name: utilityProgram.name,
      subtitle:
        utilityProgram.view_config_json.levelThreeConfig?.levelThreeSubtitle,
      tagLine: {
        get: utilityProgram.view_config_json.levelThreeConfig?.getTagline,
        give: utilityProgram.view_config_json.levelThreeConfig?.giveTagline,
      },
      summary:
        utilityProgram.view_config_json.levelThreeConfig?.levelThreeSummary,
      logoUrl: utilityProgram.view_config_json.logoUrl,
      getValueChips: (
        utilityProgram.view_config_json.levelThreeConfig?.valueChips ?? []
      )
        .filter((c) => c?.valueChipKind && c?.valueChipText)
        .filter((c) => !c?.isGivingValue)
        .map((c) => ({
          kind: c?.valueChipKind ?? '',
          text: c?.valueChipText ?? '',
        })),
      giveValueChips: (
        utilityProgram.view_config_json.levelThreeConfig?.valueChips ?? []
      )
        .filter((c) => c?.valueChipKind && c?.valueChipText)
        .filter((c) => c?.isGivingValue)
        .map((c) => ({
          kind: c?.valueChipKind ?? '',
          text: c?.valueChipText ?? '',
        })),
    }
  }, [utilityProgram])
  const { closeBottomSheet, setBottomSheetContent, config, snapToHeader } =
    useBottomSheet()
  const { goBack } = useNavigation()
  const onBack = () => {
    closeBottomSheet()
    goBack()
  }
  useEffect(() => {
    if (!utilityProgram) {
      return
    }
    if (config) {
      return
    }
    setBottomSheetContent(programRequirementsSheetConfig({ utilityProgram }))
    snapToHeader()
  }, [config, utilityProgram])
  return { utilityProgram, programContent, onBack }
}

const useMockViewModel = () => {
  return {
    utilityProgram: {} as UtilityProgram,
    programContent: null,
    onBack: () => {},
  }
}

export default useMockableViewModel({ useViewModel, useMockViewModel })
