import { FullPageCard, NavigationPage } from '@/app/components'
import { Text } from '@/components'
import {
  CarFrontIcon,
  ClockIcon,
  DatabaseIcon,
  DollarSignIcon,
  HandHelpingIcon,
  HomeIcon,
  MedalIcon,
} from 'lucide-react'
import { match } from 'ts-pattern'
import ThemedDocumentRenderer from '@/components/ThemedDocumentRenderer/ThemedDocumentRenderer'
import { sanitizeProgramName } from '@/types/utilityProgram/utils'
import useViewModel from './useViewModel'
import ExpandableContent from '@/components/ExpandableContent/ExpandableContent'

const SUMMARY_HEIGHT = 200

export default function LevelThree() {
  const { programContent, onBack } = useViewModel()

  if (!programContent) {
    return null
  }

  return (
    <FullPageCard>
      <NavigationPage
        id={'level-three-view-nav'}
        title={`${sanitizeProgramName(programContent.name)} Program`}
        subtitle={programContent.subtitle}
        className="!p-0"
        backOverride={onBack}
      >
        <div className="flex flex-col gap-4">
          <section className="flex flex-col gap-4 border border-solid border-themed-grey-100 w-full rounded-md">
            <div className="w-full flex flex-row gap-2 border-b border-solid border-themed-grey-100 px-2">
              <div className="h-20 flex-1 flex items-center justify-center flex-col">
                <MedalIcon className="text-themed-grey-500 flex-none" />
                <Text variant="body2">Get</Text>
                <Text className="text-themed-blue-500">
                  {programContent.tagLine.get}
                </Text>
              </div>
              <div className="border-r border-base-grey-100 border-solid my-2" />
              <div className="h-20 flex-1 flex items-center justify-center flex-col">
                <HandHelpingIcon className="text-themed-grey-500 flex-none" />
                <Text variant="body2">Give</Text>
                <Text className="text-themed-blue-500">
                  {programContent.tagLine.give}
                </Text>
              </div>
            </div>
            <div className="flex flex-col gap-4 px-4 pb-4">
              <div className="flex flex-col gap-2">
                <Text variant="h3">Program Summary </Text>
                <ExpandableContent contentHeight={SUMMARY_HEIGHT}>
                  <ThemedDocumentRenderer document={programContent.summary} />
                </ExpandableContent>
              </div>
              {programContent?.logoUrl && (
                <div>
                  <Text variant="h3-subheader">Sponsor</Text>
                  <img src={programContent.logoUrl} alt="sponsor logo" />
                </div>
              )}
            </div>
          </section>
          {programContent.giveValueChips.length > 0 && (
            <section className="flex flex-col p-4 gap-4 border border-solid border-themed-grey-100 rounded-md">
              <Text variant="h3">Give</Text>
              <div className="flex flex-row flex-wrap gap-2">
                {programContent.giveValueChips.map((c, index) => (
                  <ValueChip
                    key={index}
                    kind={c.kind as ValueChipKind}
                    title={c.text}
                  />
                ))}
              </div>
            </section>
          )}
          {programContent.getValueChips.length > 0 && (
            <section className="flex flex-col p-4 gap-4 border border-solid border-themed-grey-100 rounded-md">
              <Text variant="h3">Get</Text>
              <div className="flex flex-row flex-wrap gap-2">
                {programContent.getValueChips.map((c, index) => (
                  <ValueChip
                    key={index}
                    kind={c.kind as ValueChipKind}
                    title={c.text}
                  />
                ))}
              </div>
            </section>
          )}
        </div>
      </NavigationPage>
    </FullPageCard>
  )
}
type ValueChipKind = 'time' | 'vehicle-permission' | 'info' | 'cash' | 'credits'
type ValueChipProps = {
  title: string
  kind: ValueChipKind
}
function ValueChip({ title, kind }: ValueChipProps) {
  const config = match(kind)
    .with('cash', () => ({
      icon: <DollarSignIcon size={18} />,
      classNames: 'bg-themed-green-900',
    }))
    .with('credits', () => ({
      icon: <DatabaseIcon size={18} />,
      classNames: 'bg-themed-blue-500',
    }))
    .with('time', () => ({
      icon: <ClockIcon size={18} />,
      classNames: 'bg-themed-blue-500',
    }))
    .with('vehicle-permission', () => ({
      icon: <CarFrontIcon size={18} />,
      classNames: 'bg-themed-accent',
    }))
    .with('info', () => ({
      icon: <HomeIcon size={18} />,
      classNames: 'bg-themed-green-900',
    }))
    .exhaustive()

  return (
    <>
      <div
        className={`flex flex-row gap-1.5 px-3 py-2 rounded-full text-white items-center ${config.classNames}`}
      >
        {config.icon}
        <Text variant={'body1'} className="text-white text-sm">
          {title}
        </Text>
      </div>
    </>
  )
}
