import { useEffect, useRef, useState, useCallback, ReactNode } from 'react'
import { motion } from 'framer-motion'
import { ChevronDownIcon } from 'lucide-react'
import { Text } from '@/components'

type Props = {
  children: ReactNode
  contentHeight: number
  initiallyExpanded?: boolean
}

export default function ExpandableContent({
  children,
  contentHeight,
  initiallyExpanded,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showSeeMoreButton, setSeeMoreButton] = useState(
    initiallyExpanded ?? false,
  )
  const contentRef = useRef<HTMLDivElement>(null)

  const checkContentHeight = useCallback(() => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight
      setSeeMoreButton(height > contentHeight)
    }
  }, [])

  useEffect(() => {
    checkContentHeight()
    window.addEventListener('resize', checkContentHeight)
    return () => {
      window.removeEventListener('resize', checkContentHeight)
    }
  }, [children, checkContentHeight])

  return (
    <>
      <div className="relative">
        <motion.div
          ref={contentRef}
          initial={false}
          animate={{
            height: isExpanded ? 'auto' : `${contentHeight}px`,
            overflow: isExpanded ? 'visible' : 'hidden',
          }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className="relative"
        >
          {children}
        </motion.div>
        <div className="transition-all duration-300">
          {!isExpanded && showSeeMoreButton && (
            <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white to-transparent" />
          )}
        </div>
      </div>
      {showSeeMoreButton && (
        <button
          id="see-more-summary-button"
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center bg-transparent border-none mt-2 gap-1 text-themed-blue-500 hover:text-themed-blue-700"
        >
          <Text className="flex items-center text-themed-blue-500 underline">
            {isExpanded ? 'See less' : 'See more'}
          </Text>
          <motion.div
            initial={false}
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            className="flex items-center"
          >
            <ChevronDownIcon size={18} />
          </motion.div>
        </button>
      )}
    </>
  )
}
